/* ClassScheduler.css */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #eef2f5;
  color: #333;
}

.scheduler-container {
  max-width: 1200px;
  margin: 40px auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.scheduler-header {
  background: #0056b3;
  color: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scheduler-header h1 {
  margin: 0;
  font-size: 1.8rem;
  font-weight: 500;
}

/* Back button styling */
.btn.btn-back {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s;
}

.btn.btn-back:hover {
  color: #cce0ff;
}

.scheduler-content {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

/* Left-side form styling */
.form-container {
  flex: 1 1 400px;
  max-width: 400px;
  padding: 20px;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.class-selection {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.class-dropdown {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.95rem;
}

.btn.btn-autofill {
  margin-left: 10px;
  padding: 8px 12px;
  background-color: #ffc107;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s;
  font-size: 0.95rem;
}

.btn.btn-autofill:hover {
  background-color: #e0a800;
}

.scheduler-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.95rem;
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

/* Inline group for Price and Hours */
.inline-group {
  display: flex;
  gap: 15px;
  width: 100%;
}

.inline-field {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.datetime-group {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  width: 100%;
}

.datetime-field {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.datetime-input {
  width: 101%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.disclaimer {
  font-size: 0.85rem;
  color: #666;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.color-picker {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  justify-content: center;
}

.color-option {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: transform 0.2s;
}

.color-option:hover {
  transform: scale(1.1);
}

.color-option.selected {
  border: 2px solid #333;
}

.form-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 0.95rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-recurring {
  background-color: #17a2b8;
  color: #fff;
}

.btn-recurring:hover {
  background-color: #138496;
}

.btn-confirm {
  background-color: #28a745;
  color: #fff;
}

.btn-confirm:hover {
  background-color: #218838;
}

.btn-submit {
  background-color: #007bff;
  color: #fff;
}

.btn-submit:hover {
  background-color: #0069d9;
}

.recurring-section {
  margin-top: 15px;
  border-top: 1px solid #ddd;
  padding-top: 15px;
  width: 100%;
  text-align: center;
}

/* Calendar container styling */
.calendar-container {
  flex: 2 1 650px;
  padding: 20px;
  overflow-y: auto;
}

/* Custom event styling in calendar */
.custom-event {
  white-space: normal;
  padding: 2px 5px;
}
