/* Container Styling */
.class-lists-container {
  margin-top: 80px;
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.class-lists-title {
  text-align: center;
  margin-bottom: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  font-size: 2rem;
}

/* Controls Styling */
.class-lists-controls {
  display: flex;
  flex-direction: column;
  gap: 35px; /* Increased vertical gap between rows */
  margin-bottom: 20px;
}

.controls-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Increased gap between items in each row */
  /* You can also add a margin-bottom if needed: margin-bottom: 10px; */
}

/* "Show Past Classes" Button */
.past-classes-btn {
  min-width: 150px;
  font-size: 1rem;
}

/* Search Input */
.search-input {
  min-width: 250px;
  max-width: 400px;
  padding: 10px;
}

/* Date Filter Row */
.date-filter-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Increased spacing between label and date input */
}

.date-filter-label {
  font-size: 1rem;
  margin-right: 10px;
}

.date-picker {
  padding: 10px;
  max-width: 200px;
  /* Remove native appearance for a cleaner look */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.date-picker::placeholder {
  color: #aaa;
  font-style: italic;
}

/* Month Navigation */
.month-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.month-title {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.prev-next-btn {
  font-size: 1rem;
  padding: 8px 12px;
}

/* Event Item */
.event-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  flex-wrap: wrap;
}

.event-info {
  flex: 1;
  min-width: 250px;
}

.event-title {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
}

.event-datetime {
  font-size: 1rem;
  color: #555;
}

.event-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 150px;
  margin-top: 10px;
}

.user-list-btn {
  white-space: normal;
  text-align: center;
  width: 100%;
}

/* No Events */
.no-events {
  text-align: center;
  font-size: 1.2rem;
  color: #6c757d;
  margin-top: 20px;
}

/* Alert */
.alert {
  text-align: center;
}

/* Responsive Styling */
@media (max-width: 576px) {
  .event-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .event-actions {
    width: 100%;
    margin-top: 10px;
  }

  .controls-row {
    width: 100%;
    justify-content: center;
  }
}
