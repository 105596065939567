/* ParticipationForm.css */

/* Professional Form Container */
.professional-form-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Inner wrapper for additional spacing if needed */
.professional-form-inner {
  padding: 10px;
}

/* Title Styling */
.professional-form-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 2rem;
}

/* Section Title Styling */
.professional-section-title {
  color: #333;
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

/* Form Row */
.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

/* Half width columns */
.form-col-half {
  flex: 1 1 45%;
}

/* Full width columns */
.form-col-full {
  flex: 1 1 100%;
}

/* Label Styling */
.professional-form-label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

/* Required field indicator */
.required {
  color: red;
  margin-left: 5px;
}

/* Input Styling */
.professional-form-input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

.professional-form-input:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Checkbox Styling */
.custom-checkbox {
  width: 20px;
  height: 20px;
  accent-color: #007bff;
  transform: scale(1.2);
  margin-right: 10px;
}

/* Radio Group Styling */
.radio-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radio-group label {
  margin-right: 20px;
}

/* Button Styling */
.professional-form-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.professional-form-button:hover {
  background-color: #0056b3;
}

.professional-form-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Text Styling */
.professional-form-text {
  text-align: justify;
  font-size: 0.95rem;
  color: #555;
  line-height: 1.5;
}

/* Divider Styling */
.professional-form-divider {
  border: none;
  height: 1px;
  background-color: #e0e0e0;
  margin: 30px 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}
