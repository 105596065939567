.contact-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 40px 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.contact-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.contact-container p {
  text-align: center;
  margin-bottom: 10px;
  color: #555;
}

.social-media-links {
  text-align: center;
  margin-bottom: 20px;
}

.social-media-links a {
  margin: 0 10px;
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.contact-form textarea {
  min-height: 120px;
  resize: vertical;
}

.contact-form button {
  align-self: center;
  background-color: #007bff;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #0056b3;
}

.back-to-profile-top {
  margin-bottom: 20px;
  text-align: left;
}
