/* General container styling */
.checkout-form {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 100px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  font-family: "Helvetica Neue", Arial, sans-serif;
}

/* Input and Card Element styling */
input[type="text"].form-control,
#card-element {
  padding: 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.3s;
  margin-bottom: 10px;
  margin-top: 10px;
}

input[type="text"].form-control:focus,
#card-element:focus {
  border-color: #007bff;
  box-shadow: none;
}

/* Custom primary button styling */
.custom-button {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  transition: background-color 0.3s ease;
  cursor: pointer;
  margin-top: 10px;
}

.custom-button:hover {
  background-color: #0056b3;
}

/* Custom back button styling */
.back-button {
  width: auto;
  padding: 10px 20px;
  font-size: 16px;
  background-color: transparent;
  border: 2px solid #007bff;
  border-radius: 4px;
  color: #007bff;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  margin: 5px;
}

.back-button:hover {
  background-color: #007bff;
  color: #ffffff;
}
