/* src/components/RecurringDatePicker/RecurringDatePicker.css */

.recurring-date-picker {
  font-family: "Roboto", sans-serif;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  max-width: 350px;
  margin: 10px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.calendar-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.control-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.9rem;
}

.control-button:hover {
  background-color: #0056b3;
}

.month-label {
  font-size: 1.1rem;
  font-weight: 500;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-header-cell {
  text-align: center;
  font-weight: 500;
  padding: 8px 0;
  background: #f0f0f0;
  border-radius: 4px;
  font-size: 0.85rem;
}

.calendar-cell {
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s, transform 0.2s;
}

.calendar-cell:hover {
  background-color: #f7f7f7;
  transform: scale(1.05);
}

.calendar-cell.empty {
  background: transparent;
  border: none;
  cursor: default;
}

.calendar-cell.selected {
  background-color: #28a745;
  color: #fff;
  font-weight: bold;
}
